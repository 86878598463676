import './style.css'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import * as dat from 'lil-gui'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/src/all'


/**
 * Debug
 */
// const gui = new dat.GUI()

// const parameters = {
//     materialColor: '#ffeded'
// }

// gui
//     .addColor(parameters, 'materialColor')
//     .onChange(()=>{
//         material.color.set(parameters.materialColor)
//     })

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

const loadingManager = new THREE.LoadingManager( () => {
	
    const loadingScreen = document.getElementById( 'loading-screen' );
    loadingScreen.classList.add( 'fade-out' );
    
    // optional: remove loader from DOM via event listener
    loadingScreen.addEventListener( 'transitionend', onTransitionEnd );
    
} );

//update all materials
const updateAllMaterial = () =>{

    scene.traverse((child) =>{
        
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
          //   child.material.envMap = environmentMap
            // child.material.envMapIntensity = debugObject.envMapIntensity
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
        }

    })

}


/**
 * Models
 */
 const dracoLoader = new DRACOLoader(loadingManager)
 dracoLoader.setDecoderPath('/draco/')
 
 const gltfLoader = new GLTFLoader(loadingManager)
 gltfLoader.setDRACOLoader(dracoLoader)
 
 let mixer = null



//Lights
const ambiantLight = new THREE.AmbientLight('#b9d5ff', 0.2)
// ambiantLight.position.set(1, 2, 2.25)
scene.add(ambiantLight)

const directionalLight = new THREE.DirectionalLight('#b9d5ff', 2)
directionalLight.position.set(1, 1.8, 3.26)
directionalLight.castShadow = true
// directionalLight.shadow.camera.near = -1
directionalLight.shadow.camera.far = 6
directionalLight.shadow.mapSize.set(1024*3, 1024*3)
directionalLight.shadow.normalBias = 0.02
scene.add(directionalLight)



const objectsDisctance = 4



//  let tl = gsap.timeline({repeat: -1, rotation:"360"})
gltfLoader.load(
    '/models/secondcubecompfinal.glb',
    (gltf) =>
    { 
        const firstcube = gltf.scene
       firstcube.rotation.set(0,3.14, 0.4)

         if (window.innerWidth <= 329) {
            //mobile breakpoint
         //    secondCube.position.set(0, -6.3, 0)
 
            firstcube.position.x = 0
            firstcube.position.y = -1.8
            firstcube.position.z = 0
        
            firstcube.scale.set(0.11,0.11,0.11)   
        
          } else if(window.innerWidth <= 380) {
             firstcube.position.x = 0
             firstcube.position.y = -1.8
             firstcube.position.z = 0
         
             firstcube.scale.set(0.12,0.12,0.12) 
          } else if(window.innerWidth <= 420) {
             firstcube.position.x = 0
             firstcube.position.y = -1.7
             firstcube.position.z = 0
         
             firstcube.scale.set(0.12,0.12,0.12) 
          } else if(window.innerWidth <= 576) {
             firstcube.position.x = 0
             firstcube.position.y = -1.7
             firstcube.position.z = 0
         
             firstcube.scale.set(0.12,0.12,0.12) 
           } else if(window.innerWidth <= 768) {
             firstcube.position.x = 0
             firstcube.position.y = -1.7
             firstcube.position.z = 0
         
             firstcube.scale.set(0.12,0.12,0.12) 
           } else if(window.innerWidth <= 842) {
             firstcube.position.x = 0
             firstcube.position.y = -1.7
             firstcube.position.z = 0
         
             firstcube.scale.set(0.12,0.12,0.12) 
           } else if(window.innerWidth <= 992) {
             //desktop
             
               firstcube.position.x = -1.0
               firstcube.position.y = -1.7
               firstcube.position.z = 1.4
            
               firstcube.scale.set(0.14, 0.14, 0.14)
           }
           else if(window.innerWidth <= 1200) {
             //desktop
             
               firstcube.position.x = 1.1
               firstcube.position.y = 0
               firstcube.position.z = 1.4
            
               firstcube.scale.set(0.14, 0.14, 0.14)
           }
           else {
             //desktop
             
               firstcube.position.x = 1.4
               firstcube.position.y = 0
               firstcube.position.z = 1.4
            
               firstcube.scale.set(0.14, 0.14, 0.14)
           }

       console.log(firstcube.scale)
        scene.add(firstcube)
        
        gsap.registerPlugin(ScrollTrigger)

       let tween1 = gsap.to
        (firstcube.rotation, 
           {               
               duration: 60,
               x: '+=6.283',
               y: '+=6.283',
               repeat: -1, 
               ease: "none",
           });
    
          
           
           ScrollTrigger.create({
               trigger:"firstcube.rotation",
               start: "10% top",
               // markers: true,
               onLeaveBack() {
                   let yposition = firstcube.rotation.y
                   let xposition = firstcube.rotation.x
                   console.log(yposition)
                   
                   tween1.y = yposition+6.6024 //adjusted PI*2 value because of easing delay
                   tween1.x = xposition+6.6024
                   console.log(tween1.y)                    

                   gsap.to
                       (firstcube.rotation, 
                           {               
                               duration: 3,
                               x: tween1.x,
                               y: tween1.y,
                               ease: "power1.inOut"
                               
                           });
                   
               },
           })
            
           updateAllMaterial()
          
    }
)









//example gltf2
gltfLoader.load(
    '/models/docubefinal.glb',
    (gltf) =>
    {  
          
       const secondCube = gltf.scene

       secondCube.rotation.set(0,3.14, 0.4)

       if (window.innerWidth <= 329) {
           //mobile breakpoint
        //    secondCube.position.set(0, -6.3, 0)

           secondCube.position.x = 0
           secondCube.position.y = -8.3
           secondCube.position.z = 0
       
           secondCube.scale.set(0.20,0.20,0.20)   
       
         } else if(window.innerWidth <= 380) {
            secondCube.position.x = 0
            secondCube.position.y = -7.4
            secondCube.position.z = 0
        
            secondCube.scale.set(0.20,0.20,0.20) 
         } else if(window.innerWidth <= 420) {
            secondCube.position.x = 0
            secondCube.position.y = -7.0
            secondCube.position.z = 0
        
            secondCube.scale.set(0.20,0.20,0.20) 
         } else if(window.innerWidth <= 576) {
            secondCube.position.x = 0
            secondCube.position.y = -6.3
            secondCube.position.z = 0
        
            secondCube.scale.set(0.20,0.20,0.20) 
          } else if(window.innerWidth <= 768) {
            secondCube.position.x = 0
            secondCube.position.y = -6.3
            secondCube.position.z = 0
        
            secondCube.scale.set(0.20,0.20,0.20) 
          } else if(window.innerWidth <= 842) {
            secondCube.position.x = 0
            secondCube.position.y = -5.7
            secondCube.position.z = 0
        
            secondCube.scale.set(0.20,0.20,0.20) 
          } else if(window.innerWidth <= 992) {
            //desktop
            
              secondCube.position.x = 1.0
              secondCube.position.y = -5.4
              secondCube.position.z = 1.4
           
              secondCube.scale.set(0.31, 0.31, 0.31)
          }
          else if(window.innerWidth <= 1200) {
            //desktop
            
              secondCube.position.x = -0.7
              secondCube.position.y = -objectsDisctance * 1
              secondCube.position.z = 1.4
           
              secondCube.scale.set(0.31, 0.31, 0.31)
          }
          else {
            //desktop
            
              secondCube.position.x = -1.2
              secondCube.position.y = -objectsDisctance * 1
              secondCube.position.z = 1.4
           
              secondCube.scale.set(0.31, 0.31, 0.31)
          }

        console.log(secondCube.scale)
        scene.add(secondCube)
        
        gsap.registerPlugin(ScrollTrigger)

       let tween2 = gsap.to
        (secondCube.rotation, 
           {               
               duration: 60,
               x: '+=6.283',
               y: '+=6.283',
               repeat: -1, 
               ease: "none",
           });
    
          
           
           ScrollTrigger.create({
               trigger:"secondCube.rotation",
               start: "18% top",
               end: "60% bottom",
            //    markers: true,
               onEnter() {
                   let yposition = secondCube.rotation.y
                   let xposition = secondCube.rotation.x
                   console.log(yposition)
                   
                   tween2.y = yposition+6.6024 //adjusted PI*2 value because of easing delay
                   tween2.x = xposition+6.6024
                   console.log(tween2.y)                    

                   gsap.to
                       (gltf.scene.rotation, 
                           {               
                               duration: 3,
                               x: tween2.x,
                               y: tween2.y,
                               ease: "power1.inOut"
                               
                           });
                   
                },

                onEnterBack() {
                    let yposition = secondCube.rotation.y
                    let xposition = secondCube.rotation.x
                    console.log(yposition)
                    
                    tween2.y = yposition+6.6024 //adjusted PI*2 value because of easing delay
                    tween2.x = xposition+6.6024
                    console.log(tween2.y)                    
 
                    gsap.to
                        (gltf.scene.rotation, 
                            {               
                                duration: 3,
                                x: tween2.x,
                                y: tween2.y,
                                ease: "power1.inOut"
                                
                            });
                    
                 },
           })
            
           updateAllMaterial()

          
    }
)








//example gltf3
gltfLoader.load(
    '/models/arrowcube.glb',
    (gltf) =>
    {  
        const ThirdCube = gltf.scene

        ThirdCube.rotation.set(0,3.14, 0.4)
 
        if (window.innerWidth <= 329) {
            //mobile breakpoint
         //    secondCube.position.set(0, -6.3, 0)
 
            ThirdCube.position.x = 0
            ThirdCube.position.y = -16.3
            ThirdCube.position.z = 0
        
            ThirdCube.scale.set(0.30,0.30,0.30)   
        
          } else if(window.innerWidth <= 380) {
             ThirdCube.position.x = 0
             ThirdCube.position.y = -11.8
             ThirdCube.position.z = 0
         
             ThirdCube.scale.set(0.30,0.30,0.30) 
          } else if(window.innerWidth <= 420) {
             ThirdCube.position.x = 0
             ThirdCube.position.y = -11.6
             ThirdCube.position.z = 0
         
             ThirdCube.scale.set(0.30,0.30,0.30) 
          } else if(window.innerWidth <= 576) {
             ThirdCube.position.x = 0
             ThirdCube.position.y = -10.3
             ThirdCube.position.z = 0
         
             ThirdCube.scale.set(0.30,0.30,0.30) 
           } else if(window.innerWidth <= 768) {
             ThirdCube.position.x = 0
             ThirdCube.position.y = -9.3
             ThirdCube.position.z = 0
         
             ThirdCube.scale.set(0.30,0.30,0.30) 
           } else if(window.innerWidth <= 842) {
             ThirdCube.position.x = 0
             ThirdCube.position.y = -10.3
             ThirdCube.position.z = 0
         
             ThirdCubeThirdCube.scale.set(0.20,0.20,0.20) 
           } else if(window.innerWidth <= 992) {
             //desktop
             
               ThirdCube.position.x = 1.2
               ThirdCube.position.y = -objectsDisctance * 2
               ThirdCube.position.z = 1.4
            
               ThirdCube.scale.set(0.31, 0.31, 0.31)
           }
           else if(window.innerWidth <= 1200) {
             //desktop
             
               ThirdCube.position.x = 1.2
               ThirdCube.position.y = -objectsDisctance * 2
               ThirdCube.position.z = 1.4
            
               ThirdCube.scale.set(0.42, 0.42, 0.42)
           }
           else {
             //desktop
             
               ThirdCube.position.x = 1.2
               ThirdCube.position.y = -objectsDisctance * 2
               ThirdCube.position.z = 1.4
            
               ThirdCube.scale.set(0.42, 0.42, 0.42)
           }      


        scene.add(ThirdCube)
        
        gsap.registerPlugin(ScrollTrigger)

       let tween3 = gsap.to
        (ThirdCube.rotation, 
           {               
               duration: 60,
               x: 0,
               y: '+=6.283',
               repeat: -1, 
               ease: "none",
           });
    
          
           
           ScrollTrigger.create({
               trigger:"secondCube.rotation",
               start: "35% top",
               end: "85% bottom",
            //    markers: true,
               onEnter() {
                   let yposition = ThirdCube.rotation.y
                   let xposition = ThirdCube.rotation.x
                   console.log(yposition)
                   
                    tween3.y = yposition+6.6024 //adjusted PI*2 value because of easing delay
                //    tween3.x = xposition+6.6024
                   console.log(tween3.y)                    

                   gsap.to
                       (gltf.scene.rotation, 
                           {               
                               duration: 3,
                               x: 0,
                               y: tween3.y,
                               ease: "power1.inOut"
                               
                           });
                   
                },

                onEnterBack() {
                    let yposition = ThirdCube.rotation.y
                    let xposition = ThirdCube.rotation.x
                    console.log(yposition)
                    
                    tween3.y = yposition+6.6024 //adjusted PI*2 value because of easing delay
                    tween3.x = xposition+6.6024
                    console.log(tween3.y)                    
 
                    gsap.to
                        (gltf.scene.rotation, 
                            {               
                                duration: 3,
                                x: 0,
                                y: tween3.y,
                                ease: "power1.inOut"
                                
                            });
                    
                 },
           })
            
           updateAllMaterial()

          
    }
)




//particles 
const particlesCount =  100
const positions = new Float32Array(particlesCount * 3)
// console.log("allmeshes lenght: " + allMeshes.length)
for(let i = 0; i < particlesCount; i++){

    positions[i*3 + 0] = (Math.random() - 0.5) * 10
    positions[i*3 + 1] = objectsDisctance * 0.5 - Math.random() * objectsDisctance * 4 //( i removed allmeshes.lenght because alla meshes going to be removed )
    positions[i*3 + 2] = (Math.random() - 0.5) * 10

}

const particlesGeometry = new THREE.BufferGeometry()
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

//material
const particlesMaterial = new THREE.PointsMaterial({
    color: '#ffeded',
    sizeAttenuation: true,
    size: 0.03
})

//points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{

    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */

const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 6
cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
    // alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.CineonToneMapping
// renderer.toneMappingExposure = 1
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap



//cursor

const cursor= {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event)=>{
    
    if (window.innerWidth >= 500) {
        //mobile breakpoint
        cursor.x = event.clientX / sizes.width -0.5
        cursor.y = event.clientY / sizes.height -0.5
        
        
    
      } 

})

//scroll

let scrollY = window.scrollY
let currentSection = 0
let currentscrollY = 0


window.addEventListener('scroll', ()=>{

    scrollY = window.scrollY 
    

    // console.log("scrolly: "+ scrollY)
    // console.log("seizeheight: "+ sizes.height)
    // const newSection = Math.round(scrollY / sizes.height)
    // // console.log("scroll position:" + newSection )
    // if(newSection != currentSection){
    //     currentSection = newSection 
    //     // console.log("current section: "+currentSection)
    //     // if(currentSection < 3){
    //     //     // gsap.to(
    //     //     //     allMeshes[currentSection].rotation,
    //     //     //     {
    //     //     //         duration: 1.5,
    //     //     //         ease: 'power2.inOut',
    //     //     //         x: '+=6',
    //     //     //         y: '+=3'
    //     //     //     }
    //     //     // )
    //     // }

    // }

})

$('.down').click(function () {

    currentscrollY = scrollY
        
        if(currentscrollY < sizes.height){

            window.scrollTo(0,sizes.height);
        }
        // else if(currentscrollY < sizes.height*2)
        // {
 
        //     window.scrollTo(0,sizes.height*2 );
        // }
        // else if(currentscrollY < sizes.height*3)
        // {

        //     window.scrollTo(0,sizes.height*3);
        // }
    
    
    // window.scrollTo(0,scrollY+sizes.height);

});

// document.querySelector(".down").addEventListener("click", scrollbtnfn);

// function scrollbtnfn() {
//     window.scrollTo(0,sizes.height);
//   }
/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
    scrollY = window.scrollY
    //Animate camera
    camera.position.y = -(scrollY / sizes.height ) * objectsDisctance
    
    const parallaxX = cursor.y * 1
    const parallaxY = -cursor.x * 1
    // cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime
    // cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime
    cameraGroup.rotation.y += (parallaxY - cameraGroup.rotation.y) * 5 * deltaTime
    cameraGroup.rotation.x += (parallaxX - cameraGroup.rotation.x) * 5 * deltaTime

    //meshRotation
    // for(const mesh of allMeshes){
    //     mesh.rotation.x +=  deltaTime * 0.2
    //     mesh.rotation.y +=  deltaTime * 0.22
    // }

    if(mixer)
    {
        mixer.update(deltaTime)
    }

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

function onTransitionEnd( event ) {

	event.target.remove();
	
}